.links 
  width: 100%
  display: flex
  align-items: center
  & div
    flex: 1
    display: flex
    justify-content: center
    align-items: center
    height: 30px
    border-left: 1px solid grey
  & :first-child 
    border:0px

