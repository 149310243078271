@use '../_index.sass'

.title
  color: white

.cards
  display: grid
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr))
  .section
    font-size: 18px
    font-weight: bold
    padding: 10px 8px
    border-bottom: 1px solid index.$primary-dark-color
  
