@use "../index"

$button-diameter: 55px
$button-corner-distance: 10px

.adminButton 
  background-color: index.$primary-dark-color
  width: $button-diameter
  height:$button-diameter 
  border-radius:50%
  display: flex
  position: fixed
  bottom: $button-corner-distance
  cursor: pointer
  -webkit-tap-highlight-color: rgba(0,0,0,0)
  align-items: center
  justify-content: center
  z-index: 2
  & .itemContent 
    color: index.$font-color
    font-weight: bold
    font-size: 1.8em

.bottomRight 
  @extend .adminButton
  right: $button-corner-distance

.bottomLeft 
  @extend .adminButton
  left: $button-corner-distance
