.title
  color: white

.stopList
  display: flex
  flex-direction: column

.total
  margin: 10px
  padding: 10px
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  font-weight: bold
  font-size: 18px
  .tipoMoneda
    font-size: 10px
    display: inline

