@use '../_index'

.title
  font-size: 18px
  text-align: center

.selectionMapModal
  background: index.$primary-dark-color

.content
  width: 100%
  height: 100%
  display: flex
  flex-direction: column
  .buttons
    display: flex
    justify-content: center
    margin-bottom: 10px
  .instructions
    text-align: center
    font-size: 16px
    border: 2px solid index.$primary-dark-color
    border-radius: 5px
    margin-bottom: 7px
    padding: 3px

    
