.overall
  display: flex
  height: 100%
  width: 100%
  position: fixed
  top: 0px
  left: 0px
  justify-content: center
  align-items: center
  z-index: 3
  .zoom 
    display: flex
    background-color: black
    height: 100%
    width: 100%
    position: fixed
    top: 0px
    left: 0px
    opacity: .5
    justify-content: center
    align-items: center
    z-index: 3
    .closeButton
      display: block
      width: 30px
      height: 30px
      position: absolute
      top: 10px
      right: 0px
      align-items: center
      justify-content: center
      font-size: x-large
      font-weight: bolder
      color: #fff
  .pictureContainer
    z-index: 4
