@use '../../../../_index'
@use './steps.module'

$picture-diameter: 170px

.bizImg 
  display: flex
  flex-direction: column
  align-items: center
  justify-content: space-around
  flex:1
  & .pictureCircle 
    border: 5px solid index.$theme-color
    overflow: hidden
    border-radius: 50%
    height: $picture-diameter
    width: $picture-diameter
    & .pictureContainer 
      flex:1
      width: 100%
      display: flex
      justify-content: center
      align-items: center
      position: relative

.info 
  display: flex
  justify-content: space-around
  height: 55%
  .fields 
    display: flex
    flex-direction: column
    justify-content: space-around
    width: 70%
    height: 100%

