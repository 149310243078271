@use "../index"

.searchConfigBar
  position: relative
  top: index.$title-bar-height
  height: 23px
  background-color: #4f83cc
  color: white
  font-size: 0.9125rem
  .searchSpan
    padding: 5px
