.title
  color: #fff

.companyName
  padding: 8px
  font-size: 19px
  font-weight: bold
  position: relative
  .orderNumber
    font-size: 15px
  .phoneNumber
    position: absolute
    top: 12px
    right: 5px
    
  
.statusButtonDiv
  margin: 10px
  padding: 10px
  display: flex
  justify-content: center
  flex-direction: column
  .orderTotal
    padding: 8px
    font-size: 20px
    font-weight: bold
    justify-content: center
    display: flex
    .tipoMoneda
      font-size: 10px
      display: inline
  .statusButton
    color: #fff
    font-weight: bold 
