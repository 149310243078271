@use "../../../../../index"

$pic-diameter: 65px

.picture 
  flex: 0 0 85px
  display: flex
  justify-content: center
  align-items: center
  height: 90px
  padding-top: 10px
  & .pictureCircle 
    border: 2px solid index.$theme-color
    overflow: hidden
    border-radius: 50%
    height: $pic-diameter
    width: $pic-diameter
    & .pictureContainer 
      flex: 1
      width: 100%
      display: flex
      justify-content: center
      align-items: center
      position: relative
      & .pictureImg 
        width: 65px 

