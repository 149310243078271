@use './steps.module'

.advice 
  background-color: red
  color: white
  font-size: 12px
  font-weight: bold
  text-align: justify
  height: 0px
  transition: height .4s 1s
.advice2 
  height: 28px
  transition: height .8s 1.2s
.fields 
 display: flex
 justify-content: center
 .permission 
   width: 70%
   padding-bottom: 10px
   display: flex
   justify-content: center
.map 
  display: flex
  height: 100%
  width: 100%

