.general 
  display: flex
  flex-direction: column
  width: 100%
  height: calc(100% - 66px)

.stepName 
  display: flex
  justify-content: center
  align-items: flex-end
  height: 30px

.description 
  font-size: 14px
  padding: 10px
  text-align: center

