@use '../_index.sass'

.locationSelection
  display: flex
  flex-direction: row
  align-items: center
  justify-content: center
  .label
    margin: 7px
    p
      text-align: center
      font-weight: 500
  .options
    margin: 7px
    background-color: index.$primary-color
    border-radius: 8px
    span
      color: white
.routeDetails
  font-size: 14px
  font-weight: bold
.warning
  font-size: 12px
  font-weight: bold
  color: red

    

