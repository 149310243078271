.title
  color: white

.terms
  padding: 13px
  p
    padding: 7px
    text-align: justify
  ul
    padding-left: 20px
    li
      padding: 5px
    
