.orderCard
  background-color: #4f83cc
  color: #fff
  margin: 5px
  padding: 10px
  border-radius: 5px
  display: flex
  flex-direction: column
  .orderId
    display: flex
    .data
      font-weight: bold
  .duration
    display: flex
    .data
      font-weight: bold
  .timestamp
    display: flex
    .data
      font-weight: bold

