.title
  color: white

.status
  .success, .failure, .inProgress
    display: flex
    flex-direction: column
    align-items: center
    padding: 10px
    p
      padding: 10px
