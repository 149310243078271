.title
  color: #fff

.companyName
  font-weight: bold
  font-size: 19px
  padding: 8px
.section
  font-size: 18px
  font-weight: bold
  padding: 3px 8px
