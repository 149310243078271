.orderCard
  color: #fff
  font-size: 18px
  font-weight: bold
  padding: 10px
  margin: 8px
  border-radius: 8px
  .info
    display: flex
    justify-content: space-between
    align-items: center
    .tipoMoneda
      font-size: 10px
      display: inline
  .time
    display: flex
    padding: 5px 0px 
    font-size: 15px
    justify-content: space-between
    .trackingId
      background-color: black
      padding: 3px
      border-radius: 5px
      display: flex
      font-size: 14px
      font-weight: 100
      align-items: flex-end
      

