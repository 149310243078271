$bgcolor: #4f83cc

.company
  border: 3px solid #4f83cc
  border-radius: 5px
  margin: 7px 3px 8px 3px
  .header
    background-color: #01579b
    color: white
    border-bottom: 2px solid #4f83cc
    padding: 5px
    display: flex
    justify-content: space-between
    align-items: center
    .nameAndType
      .name
        font-weight: bold
        font-size: 15px
      .type
        font-size: 12px
    .quantityAndSubtotal
      width: 150px
      display: flex
      flex-direction: column
      align-items: flex-end
      .quantity, .subtotal
        font-weight: bold
        font-size: 15px
        .tipoMoneda
          font-size: 10px
          display: inline
