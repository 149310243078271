@use '../_index.sass'

.shoppingCartInformative
  position: fixed
  bottom: 10px
  left: calc(20% - 5px)
  width: 60%
  border: 2px solid index.$primary-light-color
  border-radius: 7px
  font-weight: 500
  color: white
  background-color: index.$primary-dark-color
  padding: 3px
  .flexDiv
    display: flex
    flex-direction: column
    .details
      display: flex
      justify-content: space-evenly
      .quantity, .subtotal, .delivery
        display: flex
        flex-direction: column
        .label
          font-size: 12px
          text-align: center
        .value
          font-size: 16px
          text-align: center
          .tipoMoneda
            font-size: 10px
            display: inline
    .total
      display: flex
      justify-content: center
      font-size: 18px
      padding: 3px 0px
      .label
        padding: 0px 3px
      .value
        .tipoMoneda
          font-size: 10px
          display: inline
  .foot
    .text
      text-align: center
      font-size: 11px
      a :link,:visited,:hover,:active
          font-weight: bold
          color: white


