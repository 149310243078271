.title
  color: white

.about
  padding: 13px
  ul
    padding: 13px
    display: flex
    flex-direction: column
    li
      display: flex
      flex-direction: column
      align-items: center
      padding: 3px
      text-align: center
    p
      padding: 10px
      text-align: center
.footer
  font-size: 12px
  text-align: center
