.card 
  height: 123px
  border-bottom: 1px solid green
  display: flex
  flex-direction: column
  position: relative
  .quantity
    background-color: green
    color: white
    font-size: 25px
    font-weight: bold
    width: 35px
    height: 35px
    display: flex
    align-items: center
    justify-content: center
    position: absolute
    right: 0px
    top: 18px
  .foot
    position: relative
    display: flex
    justify-content: center
    align-items: center
    height: 23px
    .subTotal
      background-color: darkred
      color: white
      font-size: 14px
      font-weight: bold
      padding: 3px
      border-radius: 5px
      .tipoMoneda
        font-size: 10px
        display: inline
