.title
  color: #fff

.companyName
  padding: 8px
  font-size: 19px
  font-weight: bold
  
.statusButtonDiv
  margin: 10px
  padding: 10px
  display: flex
  justify-content: center
  flex-direction: column
  align-items: center
  .orderTotal
    padding: 8px
    font-size: 20px
    font-weight: bold
    justify-content: center
    display: flex
    .tipoMoneda
      font-size: 10px
      display: inline
  .status
    display: flex
    font-weight: bold 
