@use '../../../_index'

.topBar
  display: flex
  justify-content: flex-end
  height: 20px
  width: 100%
  .openHours
    display: flex
    justify-content: flex-end
    position: relative
    .todaysOpenHours
      font-size: 11px
      align-self: center
      padding: 0px 2px
    .available
      font-weight: bold
      font-size: 11px
      align-self: center
      padding: 0px 2px
    .arrow
      color: index.$primary-dark-color
    .weeklySchedule
      min-width: max-content
      position: absolute
      top: 20px
      background-color: index.$primary-dark-color
      color: white
      z-index: 1
      padding: 5px
      font-size: 13px
      font-weight: bold
      display: flex
      flex-direction: column
      align-items: flex-end
  .delivery
    padding: 0px 2px
    align-self: center
    svg 
      height: 25px
      width: 25px
  .tipoNegocio 
    background-color: orangered
    color: white
    font-weight: bold
    padding: 2px 5px
    font-size: 14px
