@use '../index'

.stop
  display: flex
  margin: 8px 2px
  border-radius: 8px
  flex-direction: row
  border: 1px solid black
  overflow: hidden
  .stopNumber
    background-color: index.$primary-dark-color
    display: flex
    justify-content: center
    align-items: center
    color: #fff
    font-size: 25px
    font-weight: bold
    padding: 10px
  .companyCard
    background-color: #fff
    color: black
    width: 100%
    .nameAndType
      padding: 10px
      background-color: index.$primary-dark-color
      color: #fff
      .name
        font-size: 16px
        font-weight: bold
      .type
        font-size: 14px
    .id
      background-color: black
      color: #fff
      padding: 3px
      font-size: 14px
      width: fit-content
    .count
      padding: 3px 
      background-color: index.$primary-dark-color
      color: #fff
      font-size: 14px
      font-weight: bold
      display: flex
      justify-content: space-evenly 
      .productCount, .subtotal
        .tipoMoneda
          font-size: 10px
          display: inline


