.general
  display: flex
  align-items: flex-end
  justify-content: space-evenly
  padding: 7px
.error
  display: flex
  font-size: 11px
  justify-content: flex-end
  padding-right: 15px
  color: red
  
