@use "../../../../index"

$pic-diameter: 18px

.companyReference
  display: flex
  flex-direction: row
  .companyPicture 
    padding: 0px 5px 0px 10px
    display: flex
    justify-content: center
    align-items: center
    & .companyPictureCircle 
      border: 1px solid index.$primary-color
      overflow: hidden
      border-radius: 50%
      height: $pic-diameter
      width: $pic-diameter
      & .compaanyPictureContainer 
        flex: 1
        width: 100%
        display: flex
        justify-content: center
        align-items: center
        position: relative
  .companyName
    display: flex
    font-size: .8rem
    font-weight: 500
    align-items: center 

