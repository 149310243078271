.stop
  display: flex
  margin: 8px
  border-radius: 8px
  flex-direction: row
  border: 1px solid black
  .stopNumber
    border-radius: 8px 0px 0px 8px
    background-color: darkred
    display: flex
    justify-content: center
    align-items: center
    color: white
    font-size: 25px
    font-weight: bold
    padding: 10px
  .companyCard
    background-color: white
    color: black
    padding: 10px
    position: relative
    width: 100%
    border-radius: 8px
    .nameAndType
      .name
        font-size: 20px
        font-weight: bold
      .type
        font-size: 15px
    .id
      background-color: black
      color: white
      padding: 3px
      font-size: 14px
      border-radius: 5px
      width: fit-content
    .directions
      position: absolute
      right: 88px
      bottom: 5px
      a, a:link, a:visited, a:hover, a:active
        color: darkgreen
    .count
      background-color: darkgreen
      color: white
      width: 70px
      position: absolute
      bottom: 0px
      right: 0px
      padding: 3px 
      border-radius: 8px
      .productCount
        display: flex
        justify-content: center
        align-items: center
        font-size: 20px
        font-weight: bold
      .countLabel
        font-size: 12px
        display: flex
        justify-content: center
        align-items: center


