.multiProductSubtotal
  display: flex
  background-color: darkred
  color: white
  font-size: 13px
  font-weight: 900
  padding: 3px
  border-radius: 3px
  .tipoMoneda
    font-size: 10px
    display: inline
