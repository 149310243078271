.cardInfo 
  flex: 1
  display: flex
  flex-direction: column
  position: relative
  .nombre 
    min-height: 32px
    padding: 5px 4px 3px 0px
    display: flex
    justify-content: flex-start
    align-items: center
    overflow: hidden
    word-break: break-all
  .presentacionNegocio 
    min-height: 32px
    padding: 5px 4px 3px 0px
    font-size: 13px
    overflow: hidden
    word-break: break-all
  .authorization
    position: absolute
    right: 10px
    top: 38px
  .toast 
    position: absolute
    background-color: initial
    color: transparent
    padding: 7px
    font-size: 12px
    font-weight: bold
    border-radius: 5px
    top: 40px
    transition: 1s 
  .toast2 
    background-color: blue
    color: white
    transition: 1s 
