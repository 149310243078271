@use './steps.module'

.selection 
  display: flex
  justify-content: space-around
  height: 100%
  .fields 
    display: flex
    flex-direction: column
    justify-content: space-around
    width: 109px
    height: 100%

.cb 
  padding: 0px 4px !important

.group 
  height: 100%
  justify-content: space-between

