.title 
  color: white
.topForm 
  height: 100%
%mui-floating-button 
  width: 56px
  position: absolute
  bottom: 10px
.leftButton 
  @extend %mui-floating-button
  left: 10px
.rightButton 
  @extend %mui-floating-button
  right: 10px

