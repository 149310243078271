@use '../../../../_index.sass'

.card 
  min-height: 123px
  border-bottom: 1px solid index.$primary-dark-color
  border-right: 1px solid index.$primary-dark-color
  display: flex
  flex-direction: column
  position: relative
  .closed
    color: red
    display: flex
    position: absolute
    right: 10px
    bottom: 0px
    font-size: 12px
    font-weight: bold
  .foot
    position: relative
    display: flex
    align-items: flex-center
    height: 20px
    
.multiProductSubtotal
  justify-content: center
