@use '../_index.sass'

.profileMenuButton
  display: flex
  width: 100%
  justify-content: center
  align-items: center
  .profileButton
    width: 100%
    flex-direction: column
    justify-content: center
    align-items: center
    .picture
      display: flex
      width: 100%
      justify-content: center
      align-items: center
      .pictureCircle
        border: 2px solid index.$primary-dark-color
        overflow: hidden
        border-radius: 50%
        width: 65px
        height: 65px
        .pictureContainer
          display: flex
          justify-content: center
          align-items: center
          .picrureImg
            width: 65px
    .userName
      display: flex
      padding: 5px
      justify-content: center
    .login
      display: flex
      width: 100%
      padding: 5px
      justify-content: flex-end

    
    

.truckIcon
  width: 24px
  height: 24px
