@use "../index"

.SearchBox
  display: flex
  justify-content: space-between
  width: 100%
  & div:first-child 
    display: flex
    width: 100%
    & form 
      width: 100%
      padding-right: 18px
      & input 
        width: 100%
        padding-bottom: 0px
        background-color: #4f83cc
        color: index.$font-color
        font-size: 1.2em
        height: 35px
        border-bottom: 0px
        outline:0
        border-radius: 20px
        padding-left: 10px
        padding-right: 10px
        &::placeholder 
          color: index.$font-color
      & button, span 
        display: none
