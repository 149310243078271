@use "../index"

.TitleBar 
  background-color: index.$theme-color
  height: 60px
  width: 100%
  position: fixed
  display: flex
  align-items: center

