.backdrop
  display: flex
  height: 100%
  width: 100%
  position: fixed
  top: 0px
  left: 0px
  background-color: black
  opacity: .5
  align-items: center
  justify-content: center
  z-index: 3
