.title
  color: #fff

.recipient
  padding: 5px
  display: flex
  align-items: center
  .label, .value
    padding: 0px 5px
  .value
    font-weight: bold

.stopList
  display: flex
  flex-direction: column

.price
  display: flex
  justify-content: center
  .countAndTotal
    display: flex
    flex-direction: column
    align-items: flex-end
    justify-content: center
    padding: 30px
    font-size: 18px
    .count, .subtotal, .delivery, .total
      padding: 1px
    .subtotal, .delivery, .total
      .tipoMoneda
        font-size: 10px
        display: inline
    .total
      font-weight: 900

.statusButtonDiv
  display: flex
  justify-content: center
  align-items: center
  .statusButton
    color: #fff
    font-weight: bold
