.companyOrdersCard
  background-color: #fff
  color: black
  padding: 5px
  margin: 5px
  border: 3px solid #01579b
  border-radius: 7px
  font-weight: bold
  .name
    font-size: 19px
    margin: 5px
  .newOrderNumber
    @extend .orderNumbers
    .new
      @extend .orderNumberBadges
      background-color: darkred
  .inPreparationOrderNumber
    @extend .orderNumbers
    .inPreparation
      @extend .orderNumberBadges
      background-color: darkorange
  .readyForPickupOrderNumber
    @extend .orderNumbers
    .readyForPickup
      @extend .orderNumberBadges
      background-color: darkgreen

.orderNumbers
  display: flex
  align-items: center
  font-size: 17px

.orderNumberBadges
  display: flex
  align-items: center
  justify-content: center
  width: 20px
  height: 20px
  font-size: 19px
  color: white
  border: 2px solid #fff
  border-radius: 50%
  padding: 5px 
  margin: 1px 5px



