@use '../_index'

.title
  color: #fff

.paymentResult
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  .thanks
    font-size: 20px
    color: index.$primary-color
    font-weight: bold
    margin: 15px
    padding: 15px
  .text
    margin: 10px
    padding: 10px
    
