.cardInfo 
  flex: 1
  display: flex
  flex-direction: column
  position: relative
  & .priceRow
    position: relative
    display: flex
    align-items: flex-start
    justify-content: flex-end
    height: 20px
    & .price
      background-color: blue
      color: white
      padding: 1px 5px
      font-size: 14px
      font-weight: 900
      .tipoMoneda
        font-size: 10px
        display: inline
  & .nombre 
    min-height: 32px
    padding: 5px 4px 3px 0px
    display: flex
    justify-content: flex-start
    align-items: center
    overflow: hidden
    word-break: break-all
  & .description
    min-height: 32px
    padding: 5px 4px 3px 0px
    font-size: 13px
    overflow: hidden
    word-break: break-all
  & .toast 
    position: absolute
    background-color: initial
    color: white
    padding: 7px
    font-size: 10px
    border-radius: 5px
    top: 40px
    left: 80px
    transition: background-color 1s 
  & .toast-2 
    background-color: blue
    transition: background-color 1s 
  & .editButton 
    @extend .managementButtons
    right: 70px
    background-color: grey
  & .deleteButton 
    @extend .managementButtons
    right: 15px
    background-color: red

.managementButtons
  width: 40px
  height: 40px
  border-radius:50%
  display: flex
  position: absolute
  cursor: pointer
  -webkit-tap-highlight-color: rgba(0,0,0,0)
  align-items: center
  justify-content: center
  top: 37px
    
