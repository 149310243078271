.title
  color: #fff

.payment
  display: flex
  justify-content: center
  align-items: center
  flex-direction: column
  .phoneLabel
    padding: 15px
  .countAndTotal
    display: flex
    flex-direction: column
    align-items: flex-end
    justify-content: center
    padding: 20px
    font-size: 18px
    .count, .subtotal, .delivery, .total
      padding: 1px
    .subtotal, .delivery, .total
      .tipoMoneda
        font-size: 10px
        display: inline
    .total
      font-weight: 900
.legend
  text-align: center
  font-size: 15px
  padding: 10px
