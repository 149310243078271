@use './steps.module'

.info 
  display: flex
  height: 100%
  justify-content: center
  .fields 
    display: flex
    flex-direction: column
    width: 70%
    justify-content: space-between
    height: 35%

