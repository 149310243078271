.title
  color: #fff

.companyName
  padding: 8px
  font-size: 19px
  font-weight: bold
  position: relative
  .orderNumber
    font-size: 15px
    font-weight: bold
  .recipient
    display: flex
    flex-direction: column
    align-items: center
    position: absolute
    top: 4px
    right: 10px
    .label
      font-size: 13px
     
  
.statusButtonDiv
  margin: 10px
  padding: 10px
  display: flex
  justify-content: center
  flex-direction: column
  .orderTotal
    padding: 8px
    font-size: 20px
    font-weight: bold
    justify-content: center
    display: flex
    align-items: baseline
    .tipoMoneda
      font-size: 10px
      display: inline
