@use "../index"

.title
  color: index.$font-color

.authWidgetContainer
  display: flex
  justify-content: center
  align-items: center
  width: 100%
  height: 100%
  background-color: #4f83cc
  fieldset
    display: flex
    justify-content: space-evenly
    align-items: stretch
    flex-direction: column
    width: 50%
    height: 30%
    border: white solid 1px
    padding: 15px
    border-radius: 10px
    legend
      text-align: center
      color: #fff
