@use '../../../../_index'
@use './steps.module'

.info 
  display: flex
  justify-content: space-around
  height: 55%
  .fields 
    display: flex
    flex-direction: row
    justify-content: space-around
    width: 70%
    height: 100%

