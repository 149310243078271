.card 
  height: 123px
  border-bottom: 1px solid green
  display: flex
  flex-direction: column
  & .foot
    position: relative
    display: flex
    align-items: flex-center
    height: 20px
    
.multiProductSubtotal
  justify-content: center
