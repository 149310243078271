$theme-color: #01579b  
$font-color: #fff
$title-bar-height: 60px

//Color Palette
$primary-color: #1365C0
$primary-light-color: #5D92F3
$primary-dark-color: #003C8F

$secondary-color: #FF9100
$secondary-light-color: #FFC246 
$secondary-dark-color: #C56200

$font-color: white
$dark-background: #121212

*
  margin: 0
  padding: 0
  border: 0
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

.icons
  vertical-align: middle
  & path 
    stroke: #fff
  
.ais-Hits-list
  display: grid
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr))
  list-style: none
